// @flow
import type { ComponentType } from "react";
import styled from "styled-components";

export const Center: ComponentType<*> = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
