// @flow
import type { Node } from "react";
import React, { createContext, useReducer } from "react";
import { AppStatus } from "./config";
import { reducer } from "./reducer";
import type { Statuses } from "./config/constants";

type AppState = {
  gameState: Statuses,
  startGame: boolean,
  showDice: boolean,
};

// type AppContext = {
//   state: AppState,
//   dispatch: () => void, //for now
// };

const getInitialState = (): AppState => ({
  gameState: AppStatus.Stop,
  startGame: false,
  showDice: false,
});

// export const Context = createContext<AppContext>({
//   state: getInitialState,
//   dispatch: () => undefined,
// });

// export const Context: React.Context<?AppContext> = createContext(
// export const Context: any = createContext(getInitialState);
export const Context: React$Context<*> = createContext(getInitialState);

type StoreProps = {
  children: React$Node,
};

export const Store = ({ children }: StoreProps): Node => {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};
