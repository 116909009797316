// @flow
import type { ComponentType } from "react";
import styled from "styled-components";
import type { Theme } from "../config/types";

type ButtonProps = Theme<{
  stop: boolean,
  full: boolean,
}>;

export const Button: ComponentType<*> = styled.button(
  ({ stop, full, theme }: ButtonProps) => `
  border: 0;
  margin-bottom: 1vh;
  color: ${stop ? "white" : theme.color.text};
  background-color: ${stop ? theme.color.error : theme.color.button};
  padding: calc(0.5*${theme.spacing});
  border: 1px solid ${theme.color.border};
  border-radius: 10px;
  font-size: ${theme.fontSize};
  width: ${full ? "100%" : ""};
  `
);

export const ButtonLink: ComponentType<*> = styled.button(
  ({ theme }: Theme<{}>) => `
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
  font-size: ${theme.fontSize};
  border-bottom: 2px solid ${theme.color.primary};
`
);

export const ButtonContainer: ComponentType<*> = styled.div`
  display: flex;
  justify-content: space-between;
`;
