// @flow
import type { Score } from "../../config/types";

type State = {
  words: string[],
  lastIndex: number,
  hasTimedOut: boolean,
  showLastCard: boolean,
  countdown: number,
  score: $Shape<Score>,
  isPlaying: boolean,
};

type Action = $Shape<State>;

export const reducer = (state: State, payload: Action): State => {
  return { ...state, ...payload };
};
