// @flow
export type Statuses = "START" | "CONTINUE" | "STOP";
type StatusType = {
  Start: Statuses,
  Continue: Statuses,
  Stop: Statuses,
};

export const AppStatus: StatusType = {
  Start: "START",
  Continue: "CONTINUE",
  Stop: "STOP",
};
