// @flow
import type { Element } from "react";
import React from "react";
import { Button } from "../../components";

type ButtonNextProps = {
  hasNoTimer: boolean,
  showLastCard: boolean,
  hasTimedOut: boolean,
  onClick: () => void,
};

const ButtonNext = ({
  hasNoTimer,
  showLastCard,
  hasTimedOut,
  onClick,
}: ButtonNextProps): Element<*> => {
  if (!hasNoTimer && !showLastCard && !hasTimedOut) return <></>;
  return <Button onClick={onClick}>Volgende</Button>;
};

export default ButtonNext;
