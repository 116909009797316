// @flow

type State = {
  height: number,
  showSettings: boolean,
  wordCount: string | null,
};

type Action = $Shape<State>;

export const reducer = (state: State, payload: Action): State => {
  return { ...state, ...payload };
};
