// @flow
import type { ThemeProps } from "../config/types";

const light = "#cecece";
const primaryDark = "#00644f";
const primary = "#008670";

const theme: ThemeProps = {
  spacing: "5vh",
  fontSize: "20px",
  color: {
    light,
    primary,
    primaryDark,
    dark: "#171616",
    text: "#2d2d2d",
    bgApp: "#efefef",
    bgCard: primaryDark,
    bgCardItem: primary,
    border: light,
    button: "#e6e6e6",
    error: "#b40030",
  },
};

export default theme;
