// @flow
import * as actions from "./actions";
import type { Statuses } from "./config/constants";

type State = {
  gameState: Statuses,
  startGame: boolean,
  showDice: boolean,
};

// type Action = $Shape<State>;
type Action = {
  type: string,
  payload: boolean,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actions.APP_STATUS:
      return {
        ...state,
        gameState: (action.payload: any), //TODO: should be of type Statuses
        startGame: false,
        showDice: false,
      };
    case actions.START_GAME:
      return {
        ...state,
        startGame: !!action.payload,
      };
    case actions.SHOW_DICE:
      return {
        ...state,
        showDice: !!action.payload,
        startGame: !action.payload,
      };

    default:
      return state;
  }
};
