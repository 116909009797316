// @flow
import packageInfo from "../../package.json";
import { setting } from "../config";
import type {
  Category,
  DefaultSettings,
  Settings,
  Team,
} from "../config/types";
import { life, mixed, original } from "../data";

type ResetGame = {
  lastIndex: number,
  words?: string[] | null,
};

const {
  HAS_DICE,
  HAS_TIMER,
  HAS_AUDIO,
  NUMBER_OF_TEAMS,
  CARD_CATEGORY,
  CARDS_VISIBLE,
} = setting;

export const appVersion: string = packageInfo.version;

export const randomize = (arr: string[]): string[] => {
  // return arr.sort(() => 0.5 - Math.random(Math.floor()));

  return arr
    .map((a) => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value);
};

export const getDoubles = (arr: string[]): string[] =>
  arr.filter((item, index) => arr.indexOf(item.toLowerCase()) !== index);

export const filterDoubles = (arr: string[]): string[] => {
  const result = arr.map((x) => x.toUpperCase());
  return Array.from(new Set(result));
};

// console.log(JSON.stringify(filterDoubles(original)));

export const resetGame = (hard?: boolean): ResetGame => {
  localStorage.removeItem("lastIndex");
  localStorage.removeItem("words");

  if (hard) {
    localStorage.removeItem("diceNumber");
    localStorage.removeItem("teams");
  }

  // TODO: if hard, should also return {diceNumber, teams} here?
  return {
    lastIndex: 0,
    words: [],
  };
};

export const getWords = (category: Category): string[] => {
  if (category === "original") return original;
  if (category === "life") return life;
  return mixed;
};

export const versionMatch = (): boolean => {
  return localStorage.getItem("version") === appVersion;
};

export const loadGame = (): ResetGame => {
  const lastIndex: number = +localStorage.getItem("lastIndex") || 0;
  const storage: string = localStorage.getItem("words") || "{}";
  const words: string[] = JSON.parse(storage) || [];

  // original:
  // const words = JSON.parse(localStorage.getItem("words")) || [];

  return {
    lastIndex,
    words,
  };
};

export const saveGame = (lastIndex: string) => {
  localStorage.setItem("lastIndex", lastIndex);
};

export const defaultSettings = (): DefaultSettings => ({
  HAS_DICE,
  HAS_TIMER,
  HAS_AUDIO,
  NUMBER_OF_TEAMS,
  CARD_CATEGORY,
});

export const getSettings = (): DefaultSettings => {
  if (!versionMatch()) {
    localStorage.removeItem("settings");
    localStorage.setItem("version", appVersion);
  }
  const storedSettings = localStorage.getItem("settings");
  if (storedSettings) return JSON.parse(storedSettings);
  const initialSettings = defaultSettings();

  localStorage.setItem("settings", JSON.stringify(initialSettings));

  return initialSettings;
};

export const getTeams = (): Team[] => {
  const currSettings: Settings = JSON.parse(
    localStorage.getItem("settings") || ""
  );
  const { NUMBER_OF_TEAMS } = currSettings;

  let nr = NUMBER_OF_TEAMS;
  if (NUMBER_OF_TEAMS < 1) {
    nr = 1;
    const newSettings = {
      ...currSettings,
      NUMBER_OF_TEAMS: nr,
    };
    localStorage.setItem("settings", JSON.stringify(newSettings));
  }

  const teams = localStorage.getItem("teams");

  if (teams) return JSON.parse(teams);

  let addTeams = [];
  for (let i = 0; i < nr; i++) {
    addTeams.push({
      id: i,
      name: `Team ${i + 1}`,
      isPlaying: false,
      score: { selectedItems: [], curr: 0, prev: 0 },
    });
  }
  localStorage.setItem("teams", JSON.stringify(addTeams));

  return addTeams;
};

export const getActiveTeam = (getNext?: boolean): Team => {
  const allteams = getTeams();
  let index = -1;
  for (let i = 0; i < allteams.length; i += 1) {
    if (allteams[i].isPlaying) {
      index = i;
      break;
    }
  }
  if (index === -1) {
    allteams[0].isPlaying = true;
    index = 0;
  } else if (getNext) {
    allteams[index].isPlaying = false;
    index = (index + 1) % allteams.length;
    allteams[index].isPlaying = true;
    allteams[index].score.selectedItems = [];
  }
  localStorage.setItem("teams", JSON.stringify(allteams));
  return allteams[index];
};

export const updateTeam = (team: Team) => {
  const allteams = getTeams();
  allteams[team.id] = team;
  localStorage.setItem("teams", JSON.stringify(allteams));
};

export const sliceArray = (
  lastIndex: number,
  sliceLength: number,
  array: string[]
): string[] => {
  let tmp = [];
  for (let i = 0; i < sliceLength; i += 1) {
    tmp.push(array[(lastIndex + i) % array.length]);
  }

  return tmp;
};

export const getActiveCardNumber = (): number => {
  const lastIndex = +localStorage.getItem("lastIndex");
  return lastIndex ? lastIndex / CARDS_VISIBLE : 0;
};
