// @flow
import type { Env, Environment, Settings } from "./types";

const environment: Environment = {
  development: "DEV",
  production: "PRD",
};

export const env: Env = environment[process.env.NODE_ENV || "development"];

export const setting: Settings = {
  DEV: {
    CARD_CATEGORY: "mixed",
    CARDS_VISIBLE: 5,
    TIMEOUT: 5,
    NUMBER_OF_TEAMS: 1,
    HAS_DICE: false,
    HAS_TIMER: false,
    HAS_AUDIO: false,
    IS_STARTED: false,
    IS_RANDOM: true,
  },
  PRD: {
    CARD_CATEGORY: "original",
    CARDS_VISIBLE: 5,
    TIMEOUT: 30,
    NUMBER_OF_TEAMS: 1,
    HAS_DICE: true,
    HAS_TIMER: true,
    HAS_AUDIO: false,
    IS_STARTED: false,
    IS_RANDOM: true,
  },
}[env];
