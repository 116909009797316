// @flow
import type { ComponentType } from "react";
import styled from "styled-components";

export const Container: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
`;
