// @flow
import type { ComponentType } from "react";
import styled from "styled-components";
import { Button } from "../../components";
import type { Theme } from "../../config/types";

export const App: ComponentType<*> = styled.div(
  ({ theme }: Theme<{}>) => `
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  color: ${theme.color.text};
  padding: 0 1vh;
`
);

export const AppInfo: ComponentType<*> = styled.div(
  ({ theme }: Theme<{}>) => `
    margin-bottom: 1vh;
    margin-top: auto;

    p {
      margin: 0;
      font-size: calc(0.75 * ${theme.fontSize});
    }
  `
);

type ScoreProps = Theme<{
  isPlayingTeam: boolean,
}>;

export const Score: ComponentType<*> = styled.span(
  ({ theme, isPlayingTeam }: ScoreProps) => `
    background-color: ${isPlayingTeam ? theme.color.bgCard : theme.color.light};
    font-size: calc(0.75 * ${theme.fontSize});
    padding: calc(0.125*${theme.spacing}) calc(0.25*${theme.spacing});
    margin-left: calc(0.25*${theme.spacing});
    color: white;
    border-radius: 99px;
    display: inline-block;
`
);

export const ButtonSettings: ComponentType<*> = styled(Button)`
  background-color: ${({ theme }: Theme<{}>) => theme.color.dark};
  color: ${({ theme }: Theme<{}>) => theme.color.light};
  margin-top: calc(2 * ${({ theme }: Theme<{}>) => theme.spacing});
`;
