// @flow
import type { ComponentType, Element } from "react";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { APP_STATUS, SHOW_DICE } from "../../actions";
import { Button, ButtonContainer, Center, Container } from "../../components";
import { AppStatus } from "../../config";
import type { Theme } from "../../config/types";
import { Context } from "../../store";

const DiceNumber: ComponentType<*> = styled.h3(
  ({ theme }: Theme<{}>) => `
  background-color: ${theme.color.bgCard};
  color: white;
  padding: ${theme.spacing};
  width: ${theme.spacing};
  text-align: center;
  border-radius: 10px;
`
);

function DiceScreen(): Element<*> {
  const [, dispatch] = useContext(Context);
  const randomNumber = Math.floor((Math.random() * 3000000) % 3);
  const [diceNumber, setDiceNumber] = useState(randomNumber);
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    if (countdown >= 1) {
      const interval = setInterval(() => {
        setCountdown(countdown - 1);
        setDiceNumber(randomNumber);
      }, 100);
      return () => clearInterval(interval);
    } else {
      localStorage.setItem("diceNumber", JSON.stringify(diceNumber));
    }
  });

  return (
    <Container>
      <Center>
        <h1>Dobbel</h1>
        <p>{Math.ceil(countdown / 10)}</p>
        <DiceNumber>{diceNumber}</DiceNumber>
      </Center>
      <ButtonContainer>
        <Button
          stop
          onClick={() => {
            dispatch({ type: APP_STATUS, payload: AppStatus.Stop });
          }}
        >
          Stop
        </Button>
        {countdown === 0 && (
          <Button
            onClick={() => {
              dispatch({ type: SHOW_DICE, payload: false });
            }}
          >
            Nieuwe kaart
          </Button>
        )}
      </ButtonContainer>
    </Container>
  );
}

export default DiceScreen;
