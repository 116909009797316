// @flow
import { createGlobalStyle } from "styled-components";
import theme from ".";

export const GlobalStyle: Object = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${theme.color.bgApp};
  }

  h1 {
    text-align: center;
  }

`;
