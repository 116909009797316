// @flow
import type { ComponentType, Element } from "react";
import React from "react";
import styled from "styled-components";
import type { Theme } from "../config/types";

const HEIGHT = "35px";
const WIDTH = "55px";

const FormContainer: ComponentType<*> = styled.div(
  ({ theme }: Theme<{}>) => `
  display: flex;
  align-items: center;
  padding: 1em 0;
  margin: 0 1em;
  border-top: 1px solid ${theme.color.light};

  &:first-of-type {
    border-top: none;
  }

  label.switch {
    position: relative;
    width: ${WIDTH};
    height: ${HEIGHT};
    margin-left: auto;

    input {
      display: none;

      &:checked + .slider {
        background-color: ${theme.color.primary};

        &::before {
          transform: translateX(20px);
        }
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${theme.color.light};
      transition: 0.4s;
      border-radius: 34px;

      &::before {
        border-radius: 50%;
        position: absolute;
        content: "";
        height: 29px;
        width: 29px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        transition: 0.4s;
      }
    }
  }

  div.radio {
    margin-left: auto;
    padding-left: 1em;
    display: flex;
    flex-wrap: wrap;

    input {
      display: none;
    }
  }
`
);

type RadioItemProps = Theme<{
  checked: boolean,
}>;

const RadioItem: ComponentType<*> = styled.span(
  ({ theme, checked }: RadioItemProps) => `
  display: flex;
  align-items: center;
  padding: 1vh;
  height: ${HEIGHT};
  font-size: 14px;
  color: ${checked ? "white" : theme.color.text};
  background-color: ${checked ? theme.color.primary : theme.color.light};
`
);

const Label: ComponentType<*> = styled.h3`
  margin: 0;
  font-weight: normal;
`;

type Options = {
  name: string,
  checked: boolean,
  onChange: () => void,
};

type FormElementProps = {
  type: "switch" | "number" | "radio",
  name: string,
  onChange?: (val: any) => void, // TODO: fix void
  checked?: boolean,
  options?: Options[],
  value?: number,
  style?: InlineStyle,
};

function FormElement({
  type,
  name,
  checked,
  onChange,
  options,
  value,
  style,
  ...props
}: FormElementProps): Element<*> {
  const getFormControl = () => {
    let component = null;

    switch (type) {
      case "switch":
        component = (
          <label className="switch">
            <input
              type="checkbox"
              name={name}
              checked={checked}
              onChange={onChange}
            />
            <span className="slider" />
          </label>
        );
        break;

      case "radio":
        component = (
          <div className="radio">
            {options &&
              options.map((option) => {
                return (
                  <label key={option.name}>
                    <RadioItem checked={option.checked}>
                      {option.name}
                    </RadioItem>
                    <input
                      type="radio"
                      name={option.name}
                      onChange={option.onChange}
                      checked={option.checked}
                    />
                  </label>
                );
              })}
          </div>
        );
        break;

      default:
        component = (
          <input
            {...props}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            style={{
              ...style,
              marginLeft: "auto",
              paddingLeft: "1em",
              height: HEIGHT,
              width: WIDTH,
              fontSize: 14,
            }}
          />
        );
        break;
    }
    return component;
  };
  return (
    <FormContainer>
      <Label>{name}</Label>
      {getFormControl()}
    </FormContainer>
  );
}

export default FormElement;
