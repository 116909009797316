// @flow
import type { Element } from "react";
import React, { useContext, useEffect, useReducer } from "react";
import { APP_STATUS } from "../../actions";
import { Button, Center, Container } from "../../components";
import { AppStatus, env } from "../../config";
import type { Category } from "../../config/types";
import { Context } from "../../store";
import {
  appVersion,
  getSettings,
  getTeams,
  getWords,
  loadGame,
  resetGame,
} from "../../utils";
import Settings from "../Settings";
import StartGame from "../StartGame";
import { reducer } from "./reducer";
import { App, AppInfo, ButtonSettings, Score } from "./style";

const getInitialState = () => ({
  height: 0,
  showSettings: false,
  wordCount: null,
});

function HomeScreen(): Element<*> {
  const [{ height, showSettings, wordCount }, dispatch] = useReducer(
    reducer,
    getInitialState()
  );
  const [{ gameState }, dispatchGlobal] = useContext<React$Context<*>>(Context);
  const { lastIndex, words } = loadGame();
  const { NUMBER_OF_TEAMS, CARD_CATEGORY } = getSettings();

  const handleResize = () => dispatch({ height: window.innerHeight * 0.01 });

  const onChangeCategory = (category: Category) => {
    const wordCount: string = getWords(category).length.toLocaleString("nl");
    dispatch({ wordCount });
  };

  useEffect(() => {
    onChangeCategory(CARD_CATEGORY);
  }, [CARD_CATEGORY]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    // return window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <App style={{ "--vh": `${height}px` }}>
      {gameState !== AppStatus.Stop ? (
        <StartGame />
      ) : (
        <Container>
          {showSettings ? (
            <>
              <h1>Instellingen</h1>
              <Settings
                onChangeCategory={onChangeCategory}
                onCloseSettings={() => dispatch({ showSettings: false })}
              />
            </>
          ) : (
            <Center>
              <h1>Dertig</h1>
              <Button
                full
                onClick={() => {
                  resetGame(true);
                  dispatchGlobal({
                    type: APP_STATUS,
                    payload: AppStatus.Start,
                  });
                }}
              >
                Start nieuw spel
              </Button>
              {lastIndex && words && words.length ? (
                <Button
                  full
                  onClick={() =>
                    dispatchGlobal({
                      type: APP_STATUS,
                      payload: AppStatus.Continue,
                    })
                  }
                >
                  Speel verder
                  {getTeams().map((team) => {
                    if (NUMBER_OF_TEAMS === 1)
                      return (
                        <Score key={team.id} isPlayingTeam>
                          Score: {team.score.curr}
                        </Score>
                      );
                    return (
                      <Score key={team.id} isPlayingTeam={team.isPlaying}>
                        {team.name}: {team.score.curr}
                      </Score>
                    );
                  })}
                </Button>
              ) : null}
              <ButtonSettings onClick={() => dispatch({ showSettings: true })}>
                Instellingen
              </ButtonSettings>
            </Center>
          )}

          <AppInfo>
            {env === "DEV" && env}
            <p>Versie: {appVersion}</p>
            <p>Aantal woorden: {wordCount}</p>
          </AppInfo>
        </Container>
      )}
    </App>
  );
}

export default HomeScreen;
