// @flow
import type { Element } from "react";
import React, { useState } from "react";
import {
  getActiveCardNumber,
  getActiveTeam,
  getSettings,
  updateTeam,
} from "../../utils";
import {
  HiddenCheckbox,
  StyledCardList,
  StyledCardListItem,
  StyledCheckbox,
  Text,
} from "./style";

const { HAS_DICE } = getSettings();

type Score = {
  selectedItems: string[],
  // cur: number,
  // prev: number,
};

type CardListProps = {
  items: string[],
  score: Score,
  setScore: (score: Score) => void,
  cardNumber: number,
};

const CardList = ({
  items,
  score,
  setScore,
  cardNumber,
}: CardListProps): Element<*> => {
  const [initialScore] = useState(getActiveTeam().score.curr);
  const [prevScore] = useState(getActiveTeam().score.prev);
  const activeCard = getActiveCardNumber();
  const diceNumber = +localStorage.getItem("diceNumber");

  const handleChange = (item) => {
    const index = score.selectedItems.indexOf(item);
    const newScore = { ...getActiveTeam().score };
    const scoreToUpdate = cardNumber === activeCard ? prevScore : initialScore;

    if (index >= 0) {
      newScore.selectedItems.splice(index, 1);

      if (!HAS_DICE || newScore.curr - 1 >= scoreToUpdate) {
        newScore.curr -= 1;
      }
    } else {
      newScore.selectedItems.push(item);

      if (
        !HAS_DICE ||
        newScore.curr + newScore.selectedItems.length >
          scoreToUpdate + diceNumber
      ) {
        newScore.curr += 1;
      }
    }

    updateTeam({
      ...getActiveTeam(),
      score: newScore,
    });

    setScore(newScore);
  };

  return (
    <StyledCardList>
      {items.map((item, i) => (
        <StyledCardListItem key={i.toString()}>
          <label>
            <HiddenCheckbox
              checked={score.selectedItems.indexOf(item) >= 0}
              onChange={() => handleChange(item)}
            />
            <StyledCheckbox checked={score.selectedItems.indexOf(item) >= 0} />
            <Text>{item}</Text>
          </label>
        </StyledCardListItem>
      ))}
    </StyledCardList>
  );
};

export default CardList;
