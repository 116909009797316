// @flow
import type { Element } from "react";
import React, {
  Fragment,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { APP_STATUS, START_GAME } from "../../actions";
import { Button, ButtonContainer, Center, Container } from "../../components";
import { AppStatus, setting } from "../../config";
import { Context } from "../../store";
import {
  getActiveTeam,
  getSettings,
  getWords,
  loadGame,
  randomize,
  saveGame,
  sliceArray,
  updateTeam,
} from "../../utils";
import ButtonNext from "./ButtonNext";
import CardList from "./CardList";
import { reducer } from "./reducer";
import { Details, List, Timer } from "./style";

const { CARDS_VISIBLE, TIMEOUT, IS_RANDOM } = setting;

const getInitialState = () => ({
  words: [],
  lastIndex: loadGame().lastIndex,
  hasTimedOut: false,
  showLastCard: false,
  countdown: TIMEOUT,
  score: {
    ...getActiveTeam().score,
    selectedItems: [],
  },
  isPlaying: false,
});

function CardScreen(): Element<*> {
  const { words: wordsFromStorage } = loadGame();
  const [, dispatchGlobal] = useContext(Context);
  const [
    {
      words,
      lastIndex,
      hasTimedOut,
      showLastCard,
      countdown,
      score,
      isPlaying,
    },
    dispatch,
  ] = useReducer(reducer, getInitialState());
  const [audio] = useState(new Audio("/timer.mp3"));

  const cardNumber = lastIndex / CARDS_VISIBLE + 1;
  const diceNumber = +localStorage.getItem("diceNumber");

  const {
    HAS_DICE,
    HAS_TIMER,
    HAS_AUDIO,
    NUMBER_OF_TEAMS,
    CARD_CATEGORY,
  } = getSettings();
  const items = sliceArray(lastIndex, CARDS_VISIBLE, words);
  const { name } = getActiveTeam();
  const saveIndex = () => {
    const indexToStart = lastIndex + CARDS_VISIBLE;
    saveGame(indexToStart.toString());
  };
  const stopGame = () => {
    dispatch({ isPlaying: false });
    saveIndex();
    getActiveTeam(true);

    // TODO: refactor timeout
    setTimeout(() => {
      dispatchGlobal({ type: APP_STATUS, payload: AppStatus.Stop });
    }, 100);
  };

  useEffect(() => {
    if (!HAS_AUDIO) return;
    if (HAS_AUDIO && HAS_TIMER) {
      isPlaying ? audio.play() : audio.pause();

      audio.addEventListener("ended", () => dispatch({ isPlaying: false }));
    }
    return () => {
      audio.removeEventListener("ended", () => dispatch({ isPlaying: false }));
    };
  }, [HAS_AUDIO, HAS_TIMER, audio, isPlaying]);

  useEffect(() => {
    if (!HAS_TIMER) return;
    dispatch({ hasTimedOut: false });

    if (countdown === 5) {
      dispatch({ isPlaying: true });
    }

    if (countdown >= 1) {
      const interval = setInterval(() => {
        dispatch({ countdown: countdown - 1 });
      }, 1000);
      return () => clearInterval(interval);
    } else {
      dispatch({ hasTimedOut: true });
    }
  }, [HAS_TIMER, countdown]);

  const getWordsByCategory = () => {
    if (wordsFromStorage && wordsFromStorage.length) return wordsFromStorage;
    const wordsByCategory = getWords(CARD_CATEGORY);

    return IS_RANDOM ? randomize(wordsByCategory) : wordsByCategory;
  };

  const onClickNext = () => {
    if (!HAS_TIMER) {
      saveIndex();
      dispatch({ showLastCard: false, hasTimedOut: true });
    }
    if (showLastCard) {
      dispatch({ showLastCard: false, hasTimedOut: true });
    }
    if (hasTimedOut && !showLastCard) {
      saveIndex();

      // // copy score.curr to score.prev as starting point when navigating back
      const updatePrevScore = {
        ...score,
        prev: score.curr,
      };
      updateTeam({
        ...getActiveTeam(),
        score: updatePrevScore,
      });

      // // go to next team
      getActiveTeam(true);
      dispatchGlobal({ type: START_GAME, payload: false });
    }
  };
  if (hasTimedOut && !showLastCard) {
    saveIndex();
  }

  useEffect(() => {
    const words = getWordsByCategory();
    localStorage.setItem("words", JSON.stringify(words));
    dispatch({ words: words });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {!hasTimedOut || showLastCard ? (
        <Fragment>
          <CardList
            cardNumber={cardNumber}
            items={items}
            score={score}
            setScore={(sc) => dispatch({ score: sc })}
          />

          <Details>
            <h2 style={{ marginBottom: 0 }}>Kaart {cardNumber}</h2>
            <List>
              {HAS_DICE && <li>Gedobbeld: {diceNumber}</li>}
              {NUMBER_OF_TEAMS > 1 && <li>{name}</li>}
              <li>Score: {getActiveTeam().score.curr}</li>
            </List>
          </Details>
        </Fragment>
      ) : (
        <Fragment>
          <Center>
            {HAS_TIMER && <h1>De tijd is om!</h1>}
            <Button
              full
              onClick={() =>
                dispatch({ showLastCard: true, hasTimedOut: true })
              }
            >
              Bekijk laatste kaart
            </Button>
          </Center>
        </Fragment>
      )}

      <ButtonContainer>
        <Button stop onClick={() => stopGame()}>
          Stop
        </Button>
        <ButtonNext
          hasNoTimer={!HAS_TIMER}
          showLastCard={showLastCard}
          hasTimedOut={hasTimedOut}
          onClick={onClickNext}
        />
        {HAS_TIMER && !hasTimedOut && <Timer>{countdown}</Timer>}
      </ButtonContainer>
    </Container>
  );
}

export default CardScreen;
