// @flow
import type { Element } from "react";
import React, { useContext } from "react";
import { APP_STATUS, SHOW_DICE } from "../../actions";
import { Button, ButtonContainer, Center, Container } from "../../components";
import { AppStatus } from "../../config";
import { Context } from "../../store";
import { getActiveTeam, getSettings, loadGame } from "../../utils";
import Card from "../Card";
import Dice from "../Dice";

function StartGameScreen(): Element<*> {
  const [{ startGame, showDice }, dispatch] = useContext(Context);
  const { lastIndex } = loadGame();
  const { HAS_DICE, NUMBER_OF_TEAMS } = getSettings();
  const activeTeam = getActiveTeam().name;

  if (showDice) return <Dice />;

  return startGame ? (
    <Card />
  ) : (
    <Container>
      <Center>
        <h1>{lastIndex === 0 ? "Nieuw spel" : "Speel verder"}</h1>
        {NUMBER_OF_TEAMS > 1 && <p>{activeTeam}</p>}
      </Center>
      <ButtonContainer>
        <Button
          stop
          onClick={() =>
            dispatch({ type: APP_STATUS, payload: AppStatus.Stop })
          }
        >
          Stop
        </Button>
        {HAS_DICE ? (
          <Button onClick={() => dispatch({ type: SHOW_DICE, payload: true })}>
            Dobbel
          </Button>
        ) : (
          <Button onClick={() => dispatch({ type: SHOW_DICE, payload: false })}>
            Nieuwe kaart
          </Button>
        )}
      </ButtonContainer>
    </Container>
  );
}

export default StartGameScreen;
