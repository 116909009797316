// @flow
import type { Element } from "react";
import React, { useState } from "react";
import styled from "styled-components";
import { Button, ButtonLink, FormElement } from "../../components";
import type {
  Category,
  DefaultSettings,
  SettingKeys,
} from "../../config/types";
import theme from "../../theme";
import { defaultSettings as defSet, getSettings, resetGame } from "../../utils";

const SettingsContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 1em;
`;

const FormWrapper = styled.div`
  margin-bottom: 1em;
`;

type SettingsScreenProps = {
  onCloseSettings: () => void,
  onChangeCategory: (param: Category) => void,
};

function SettingsScreen({
  onCloseSettings,
  onChangeCategory,
}: SettingsScreenProps): Element<*> {
  const defaultSettings = defSet();
  const [settings, setSettings] = useState(getSettings());

  const changeSetting = (
    setting: SettingKeys,
    val: string | boolean | number
  ) => {
    const newValue = typeof val === "number" && val < 1 ? "" : val;
    const newSettings: DefaultSettings = {
      ...settings,
      [(setting: string)]: newValue,
    };

    setSettings(newSettings);
    localStorage.setItem("settings", JSON.stringify(newSettings));
  };

  const radioOptions = [
    {
      name: "Origineel",
      checked: settings.CARD_CATEGORY === "original",
      onChange: () => {
        resetGame();
        changeSetting("CARD_CATEGORY", "original");
        onChangeCategory("original");
      },
    },
    {
      name: "Everyday Life",
      checked: settings.CARD_CATEGORY === "life",
      onChange: () => {
        resetGame();
        changeSetting("CARD_CATEGORY", "life");
        onChangeCategory("life");
      },
    },
    {
      name: "Gemixt",
      checked: settings.CARD_CATEGORY === "mixed",
      onChange: () => {
        resetGame();
        changeSetting("CARD_CATEGORY", "mixed");
        onChangeCategory("mixed");
      },
    },
  ];

  return (
    <>
      <SettingsContainer>
        <form>
          <FormWrapper>
            <FormElement
              type="switch"
              name="Dobbelsteen"
              checked={settings.HAS_DICE}
              onChange={() => changeSetting("HAS_DICE", !settings.HAS_DICE)}
            />
            <FormElement
              type="switch"
              name="Timer"
              checked={settings.HAS_TIMER}
              onChange={() => changeSetting("HAS_TIMER", !settings.HAS_TIMER)}
            />
            {settings.HAS_TIMER && (
              <FormElement
                type="switch"
                name="Geluid"
                checked={settings.HAS_AUDIO}
                onChange={() => changeSetting("HAS_AUDIO", !settings.HAS_AUDIO)}
              />
            )}
            <FormElement
              type="number"
              pattern="[0-9]*"
              name="Aantal teams"
              // min={1}
              // max={20}
              value={settings.NUMBER_OF_TEAMS}
              onChange={(event: SyntheticEvent<*>) => {
                resetGame();
                const val = +event.currentTarget.value;
                changeSetting("NUMBER_OF_TEAMS", val);
              }}
              style={{
                outline: "none",
                borderWidth: 2,
                borderStyle: "solid",
                borderColor:
                  settings.NUMBER_OF_TEAMS < 1
                    ? theme.color.error
                    : theme.color.border,
              }}
            />

            <FormElement type="radio" name="Categorie" options={radioOptions} />
          </FormWrapper>
        </form>
      </SettingsContainer>
      {settings.NUMBER_OF_TEAMS >= 1 && (
        <Button onClick={onCloseSettings}>Opslaan en sluiten</Button>
      )}
      {JSON.stringify(settings) !== JSON.stringify(defaultSettings) && (
        <ButtonLink
          style={{ alignSelf: "center", marginTop: "1em" }}
          onClick={() => {
            localStorage.removeItem("settings");
            setSettings(defaultSettings);
            onChangeCategory(defaultSettings.CARD_CATEGORY);
          }}
        >
          Reset instellingen
        </ButtonLink>
      )}
    </>
  );
}

export default SettingsScreen;
