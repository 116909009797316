// @flow
import type { ComponentType } from "react";
import styled from "styled-components";
import type { Theme } from "../../config/types";

export const StyledCardList: ComponentType<*> = styled.ul(
  ({ theme }: Theme<{}>) => `
  list-style: none;
  margin: -1vh;
  background-color: ${theme.color.bgCard};
  padding: 0;
  border-radius: ${theme.spacing};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretch;
  align-items: space-between;
`
);

export const StyledCardListItem: ComponentType<*> = styled.li(
  ({ theme }: Theme<{}>) => `
  display: flex;
  flex: 1;
  align-items: center;
  color: white;
  padding: ${theme.spacing};
  font-size: ${theme.fontSize};
  position: relative;

  &:nth-child(odd) {
    background-color: ${theme.color.bgCardItem};
  }
`
);

export const Timer: ComponentType<*> = styled.span(
  ({ theme }: Theme<{}>) => `
  background-color: ${theme.color.bgCardItem};
  color: white;
  border-radius: 99px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`
);

export const Details: ComponentType<*> = styled.div(
  ({ theme }: Theme<{}>) => `
  margin calc(0.5*${theme.spacing}) 1vh;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  h2 {
    font-size: ${theme.fontSize}
  }
`
);

export const Text: ComponentType<*> = styled.span`
  z-index: 99;
  position: relative;
  text-transform: uppercase;
`;

export const List: ComponentType<*> = styled.ul(
  ({ theme }: Theme<{}>) => `
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin-left: calc(0.5*${theme.spacing});
  }
`
);

// $FlowFixMe
export const HiddenCheckbox: ComponentType<*> = styled.input.attrs(() => ({
  type: "checkbox",
}))`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:checked ~ ${Text} {
    text-decoration: line-through;
  }
`;

type StyledCheckboxProps = {
  checked: boolean,
};

export const StyledCheckbox: ComponentType<*> = styled.div(
  ({ checked }: StyledCheckboxProps) => `
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 150ms;

  ${
    checked
      ? `
    background: repeating-linear-gradient(-55deg, #222, #222 10px, #333 10px, #333 20px);
    background-attachment: fixed;
  `
      : ""
  }
`
);
